<template>
  <div class="reinstate-reservation-sidebar">
    <div class="reinstate-reservation-sidebar--content">
      <div style="display: flex; align-items: center; padding-top: 15px">
        <CRIcon view-box="0 0 24 24" :width="20" :height="20" color="red">
          alert_outline
        </CRIcon>
        <p style="padding-left: 8px; color: #e10055; margin: 0">
          Reinstate Reservation for {{ managedId || '' }}?
        </p>
      </div>
    </div>
    <CRButton
      id="reinstate-reservation-save-btn"
      :loading="loading"
      class="reinstate-reservation-sidebar action-btn"
      color="primary"
      text-color="white"
      @click="submit"
    >
      Reinstate
    </CRButton>
  </div>
</template>

<script>
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'
import { mapActions } from 'vuex'

export default {
  components: {},
  props: {
    reservationId: {
      type: Number,
      default: null,
    },
    managedId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      this.loading = true
      await this.$store
        .dispatch(
          'reservations/reinstateReservationRequest',
          this.reservationId
        )
        .catch((error) => {
          this.loading = false
          setTimeout(() => {
            this.showAlert({
              type: 'error',
              message: 'Issue requesting reinstatement.',
            })
          }, 500)
          return false
        })
      EventBus.$emit('refresh-detail')
      EventBus.$emit('refresh-reservation-detail')
      setTimeout(() => {
        this.showAlert({
          type: 'success',
          message: 'Reinstatement ticket created.',
        })
      }, 500)
      this.loading = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.reinstate-reservation-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px 40px 0 40px;
  }
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  font-size: 18px;
  bottom: 0;
  width: 500px !important;
  height: 71px !important;
  padding: 24px 0;
  width: inherit;
  border-radius: 0;
  background: $primary;
  z-index: 3;
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
